import styled from "styled-components";
import { Title } from "./Overview";
import Partner1 from "../../assets/Partner1.png";
import Partner2 from "../../assets/Partner2.png";
import Partner3 from "../../assets/Partner3.png";




const Partners = () =>  {



    return (
        <>
            <Container>
                <Panel>
                    <Title>OUR PARTNERS</Title>
                    <Content>
                        <ImageBox>
                            <img src={Partner1} alt=""/>
                        </ImageBox>
                        <ImageBox>
                            <img src={Partner2} alt=""/>
                        </ImageBox>
                        <ImageBox>
                            <img src={Partner3} alt=""/>
                        </ImageBox>
  
                    </Content>
                    
                  
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F3F3F3;
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`;

const Content = styled.div`
    width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const ImageBox = styled.div`
    // width: 300px;  
    display: flex;
    justify-content: center;
    img{
        width: 170px;
        
        // height: 100px;
    }
    .special{
        width: 180px;
    }
`;




export default Partners;