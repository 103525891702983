import styled from "styled-components";
// import Wheel from "../../assets/mission.png";
import T3 from "../../assets/P3.png";
import T2 from "../../assets/P2.png";
import T4 from "../../assets/P4.png";
import T1 from "../../assets/P1.png";
import { GuideButton } from "./Guidebook";
import banner from "../../assets/Team-banner.jpg";

const MeetTeam = () => {

    return (
        <>
            <ImgBox>
                <img src={banner} alt="about" />
            </ImgBox>
            <Container>
                <InfoPanel>
                    {/* <Panel>
                        <img src={Wheel} alt="rocket" />
                        <Title>Meet the Team</Title>
                    </Panel> */}
                    <Box>
                        <Detail>
                            <Intro>
                                Welcome to Kicksat Preparation, where we are dedicated to helping you succeed on your journey to a bright future. Our team is comprised of experienced professionals in the education sector, who are passionate about using our global expertise to guide and support you on your path to success. Whether you are preparing for a big exam or looking to build your skills and knowledge, we are here to help you reach your full potential and achieve your goals. Let us be a part of your journey and join us on the road to success!
                            </Intro>
                        </Detail>
                    </Box>
                    <Box>
                        <SmallBox>
                            <ImageBox>
                                <img src={T3} alt="Founder" />
                            </ImageBox>
                            <Name>DANISH KHAN</Name>
                            <p>Founder and CEO</p>
                        </SmallBox>
                        <Detail>
                            <Intro>
                                With over 5 years of experience in the education sector, Danish is an experienced SAT English instructor and expert in developing interactive programs for personal and professional development. . He is driven by a desire to utilize technology for positive change, and has established multiple successful ventures and startups. Mr. Khan has a solid history of aiding students and young professionals in honing their skills, gaining experience, and launching their careers. He has also participated in international leadership programs and is currently focused on creating innovative products and bringing new tech initiatives to fruition. He is always available to help and support students on the personal and professional level by providing them with relevant opportunities to learn and grow.
                            </Intro>
                            <InfoButton href="https://www.linkedin.com/in/danishkhandani/">
                                Connect with Danish Khan over LinkedIn
                            </InfoButton>
                        </Detail>
                    </Box>
                    <Box>
                        <SmallBox>
                            <ImageBox>
                                <img src={T2} alt="Founder" />
                            </ImageBox>
                            <Name>Rohan Hussain</Name>
                            <p>Co-Founder and SAT Math Instructor</p>
                        </SmallBox>
                        <Detail>
                            <Intro>
                                Rohan is a skilled software engineer who has developed web applications and various systems, including a Clinic Management System and an LMS and Acquisition system. He has led a government-funded project and values delivering high-quality code and promoting positive team dynamics. As an SAT Math instructor, Rohan Hussain has gained a reputation as a favorite among students due to his effective teaching tactics. With a dedication to helping his students succeed, Rohan has consistently been able to improve their performance on the SAT and achieve their academic goals through his interactive and engaging approach to instruction.
                            </Intro>
                            <InfoButton href="https://www.linkedin.com/in/rohan-hussain/">
                                Connect with Rohan Hussain over LinkedIn
                            </InfoButton>
                        </Detail>
                    </Box>
                    <Box>
                        <SmallBox>
                            <ImageBox>
                                <img src={T4} alt="Founder" />
                            </ImageBox>
                            <Name>Arooba Ali</Name>
                            <p>Marketing Lead</p>
                        </SmallBox>
                        <Detail>
                            <Intro>
                                Arooba is our Marketing Lead at Kicksat Preparation with a strong background in edtech training, facilitation, and design thinking. She has a knack for looking at the world through a creative lens and helping others do the same. Arooba is currently pursuing a Masters in Digital and Strategic Marketing at the University of Bradford and has recently entered the tech industry, working on a NFT project at a NY-based crypto/Web3 consultancy. In the past, she has gained experience in the training and development sector, including designing and executing experiential learning programs, coaching students, and creating fellowship programs for startups. Arooba is also the founder of a social initiative and has served as Student Council President, showcasing her dedication to making a positive impact. At Kicksat Preparation, Arooba uses her unique perspective, problem-solving abilities, and drive for success to excel in her role as Marketing Lead.
                            </Intro>
                            <InfoButton href="https://www.linkedin.com/in/aroobaali/">
                                Connect with Arooba Ali over LinkedIn
                            </InfoButton>
                        </Detail>
                    </Box>
                    <Box>
                        <SmallBox>
                            <ImageBox>
                                <img src={T1} alt="Founder" />
                            </ImageBox>
                            <Name>Fizza Afreen</Name>
                            <p>Manager</p>
                        </SmallBox>
                        <Detail>
                            <Intro>
                                Fizza Afreen is a highly capable and experienced professional with a background in teaching and career counseling. As Manager at Kicksat Preparation, she is committed to ensuring that all tasks are completed efficiently and to the highest quality. With her strong attention to detail and exceptional organizational skills, Fizza excels at managing projects, coordinating schedules, and overseeing daily operations. She has worked as a teacher at Bloomfield Hall School and a career counselor at The Citizens Foundation. She has a thorough understanding of the needs and goals of students which helps us manage the programs accordingly.
                            </Intro>
                            <InfoButton href="https://www.linkedin.com/in/fizza-afreen-5a7619138/">
                                Connect with Fizza Afreen over LinkedIn
                            </InfoButton>
                        </Detail>
                    </Box>
                </InfoPanel>

            </Container>
        </>
    );
}

const ImgBox = styled.div`
    display: flex;
    img{
        width: 100%;
        max-width: 1000px;
        margin: auto;
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding-top: 20px;
    display: flex;
    justify-content: center;
`;

const InfoButton = styled(GuideButton)`
    width: 350px;
    height: 30px;
    font-size: 15px;
    // margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        width: 90%;
        font-size: 11px;
    }
`;

const Name = styled.h3`
    margin: 0px;
    font-size: 22px;
    font-weight: 800;
    color: black;
    // margin-top: 50px;
    
`;

const ImageBox = styled.div`
    width: 200px;  
    padding: 10px;  
    display: block;
    padding-bottom: 30px;
    justify-content: center;
    img{
        width: 160px;
        border-radius: 100%;
        // height: 100px;
        margin: auto;
        display: block;
    }
    .special{
        width: 180px;
    }
`;

const InfoPanel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Box = styled.div`
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: flex; 
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
`;

const SmallBox = styled.div`
    width: 100%;
    // min-height: 300px;
    color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    padding-right:
    // text-align: justify;
    img{
        height: 200px;
        width: auto;
    p{
        text-align:center;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 90%;
    }
`;


// const Panel = styled.div`
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//     padding: 20px;
//     img{
//         margin-top: 30px;
//         width: 100px;
//         height: auto;
//     }
//     @media screen and (max-width: 768px) {
//         margin-left: auto;
//         margin-right: auto;
//         width: 90%;
//     }
// `;


// const Title = styled.h3`
//     margin: 0px;
//     width: fit-content;
//     font-size: 50px;
//     color: black;
//     font-weight: 900;
//     text-style: italic;
//     background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// `;

const Detail = styled.p`
    font-size: 18px;
    color: black;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img{
    }
    p{
        text-align: justify;
    }
`;

const Intro = styled.div`
    font-size: 18px;
    color: black;
    margin-top: 0px;
    text-align: justify;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;

export default MeetTeam;