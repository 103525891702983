import styled from "styled-components";
import Header from "./Components/Header";
import MyFooter from "./Components/Footers";
import MeetTeam from "./Components/MeetTeam";
import Stories from "./Components/StoriesGreyInfo";

const About = () =>  {

    return (
        <>
            <Container>
                <Header/>
                <MeetTeam/>
                <Stories/>
                <MyFooter/>
            </Container>
        </>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: white;
`;



export default About;