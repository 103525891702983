import styled from "styled-components";
// import { Title } from "./Overview";
import Banner from "../../assets/GRE-banner.jpg";




const GREBanner = () => {



    return (
        <>
            <Container>
                <ImageBox>
                    <img src={Banner} alt="" />
                </ImageBox>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F3F3F3;
`;


const ImageBox = styled.div`
    // width: 300px;
    padding-top: 70px;  
    display: flex;
    justify-content: center;
    img{
        width: 100vw;
        
        // height: 100px;
    }
`;




export default GREBanner;