import styled from "styled-components";
import { Title } from "./Overview";
import banner from "../../assets/About-banner.jpg";

const Info = () => {




    return (
        <>
            <Container>
                <Panel>
                    <img src={banner} alt="about" />
                    {/* <TitleMobile>ABOUT KICKSAT PREPARATIONS</TitleMobile> */}
                    <Subtitle>
                        <p>At Kicksat Preparations, we are dedicated to helping students overcome the challenges of standardized testing and achieve their educational goals. Our team, composed of dedicated students and young professionals, is passionate about making a positive impact in the community and providing accessible, affordable test preparation services.</p>
                        <p>We offer a range of services, including SAT, GRE, IELTS, and TOEFL preparation, to support students throughout their academic journey. Our online test preparation sessions focus on providing valuable feedback on each student's performance and a Kicksat representative is always available to answer any questions. In addition, students have access to a wide range of online practice materials and books to help them improve their analytical skills, reading speed, understanding of test questions, and time management.</p>
                        <p>But we don't stop there. At Kicksat Preparations, we believe that financial constraints should not stand in the way of a student's academic dreams. That's why we are the only institute in Pakistan to offer up to 100% financial aid for live test preparation sessions. Our goal is to help more than 10,000 Pakistani students achieve their academic goals by the end of 2025. We understand that financial constraints can be a barrier for many students in Pakistan who wish to attend top institutions like LUMS, IBA, and international master and PhD programs. That's why we work to create awareness about different scholarship programs that can help students overcome these barriers. We offer free mentorship, application guidelines, and extra test preparation material to all our students to help them succeed.</p>
                        <p>We have a proven track record of success, having worked with over 450 students to date. Of these, more than 200 have completed their test preparation on 100% financial aid and more than 300 have studied on some percentage of financial aid. 57 of our students are currently studying at LUMS, while others are attending institutions in the US, Turkey, and Canada. We offer free mentorship, application guidelines, and extra test preparation material to all our students to help them succeed.</p>
                        <p>Join the Kicksat Preparations community and take the first step towards achieving your academic dreams. Sign up for our test preparation services today and let us help you kick down the barriers to success.</p>

                    </Subtitle>
                    <Content>


                    </Content>

                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #F3F3F3;
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    img{
        width: 100%;
        max-width: 1000px;
        margin: auto;
    }
`;

const Content = styled.div`
    // width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 977px) {
        display: block;
    }
`;

// const TitleMobile = styled.h3`
//     margin: 0px;
//     font-size: 30px;
//     color: black;
//     font-weight: 800;
//     margin-top: 100px;
//     margin-bottom: 30px;
//     text-align: center;
//     @media screen and (max-width: 768px) {
//         font-size: 24px;
//     }
// `;





const Subtitle = styled(Title)`
    font-size: 20px;
    margin-top: 0px;
    font-weight: 300;
    max-width: 900px;
    text-align: justify;
    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
`;



export default Info;