import styled from "styled-components";


const Stats = () => {

    return (
        <>
            <Container>
                <PanelLg>
                    <StatBox>
                        <Stat>550+</Stat>
                        <StatMid>Students</StatMid>
                        <Stat>Taught</Stat>
                    </StatBox>
                    <StatBox>
                        <Stat>180+</Stat>
                        <StatMid>Successful</StatMid>
                        <Stat>Admissions</Stat>
                    </StatBox>
                    <StatBox>
                        <Stat>70+</Stat>
                        <StatMid>Admissions at</StatMid>
                        <Stat>LUMS</Stat>
                    </StatBox>
                    <StatBox>
                        <Stat>200+</Stat>
                        <StatMid>Students</StatMid>
                        <Stat>Taught Free</Stat>
                    </StatBox>
                    <StatBox>
                        <Stat>48</Stat>
                        <StatMid>Sessions</StatMid>
                        <Stat>Conducted</Stat>
                    </StatBox>
                </PanelLg>
            </Container>
            <ContainerMobile>
                <PanelSm>
                    <StatBox>
                        <Stat>550+</Stat>
                        <StatMid>Students</StatMid>
                        <Stat>Taught</Stat>
                    </StatBox>
                </PanelSm>
                <PanelSm>
                    <StatBox>
                        <Stat>180+</Stat>
                        <StatMid>Successful</StatMid>
                        <Stat>Admissions</Stat>
                    </StatBox>
                </PanelSm>
                <PanelSm>
                    <StatBox>
                        <Stat>70+</Stat>
                        <StatMid>Admissions at</StatMid>
                        <Stat>LUMS</Stat>
                    </StatBox>
                </PanelSm>
                <PanelSm>
                    <StatBox>
                        <Stat>200+</Stat>
                        <StatMid>Students</StatMid>
                        <Stat>Taught Free</Stat>
                    </StatBox>
                </PanelSm>
                <PanelSm>
                    <StatBox>
                        <Stat>48</Stat>
                        <StatMid>Sessions</StatMid>
                        <Stat>Conducted</Stat>
                    </StatBox>
                </PanelSm>
            </ContainerMobile>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    padding-top: 30px;
    padding-bottom: 30px;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const ContainerMobile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    padding-top: 30px;
    padding-bottom: 30px;
    text-transform: uppercase;
    @media screen and (min-width: 769px) {
        display: none;
    }
`;

const PanelLg = styled.div`
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-left: 20px;
    margin-right: 20px;
    @media screen and (max-width: 1100px) {
        // max-width: 800px;
    }
`;

const PanelSm = styled(PanelLg)`
    max-width: 700px;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
        width:85%;
    }
`;

const StatBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 350px;
    @media screen and (max-width: 1100px) {
    width: 300px;
    }
`;

const Stat = styled.h3`
    margin: -6px;
    font-size: 20px;
    font-weight: 700;
    @media screen and (max-width: 1100px) {
    font-size: 20px;
    }
`;

const StatMid = styled(Stat)`
    font-weight: 200;
`;



export default Stats;
