import styled from "styled-components";
import { Title } from "./Overview";
import B1 from "../../assets/B1.jpg";
import B2 from "../../assets/B2.jpg";
import B3 from "../../assets/B3.jpg";

const Info = () => {




    return (
        <>
            <Container>
                <img id="blog1" src={B1} alt="blog" />
                <Panel>
                    <Subtitle>
                        <p>
                            We all need to make decisions at some stage of our lives and being students some of the decisions we make have a long-lasting impact on our lives. That includes selecting your major subject to study at school and college and later taking it as a major in university. The whole process of subject selection often requires a lot of decision making and puts forward the need of having a mentor/counselor to help you select the subject you are most passionate about. Besides that, a mentor also provides help regarding academic goals and also helps students to polish their skills required for the field they are interested in and help them cope with their hard times so they continue their educational progress seamlessly.
                        </p>
                        <p>
                            Here are some points regarding the importance and benefits of mentoring:
                        </p>
                        <ol>
                            <li><b>It helps you make an informed choice</b></li>
                            Guidance and counseling is the process that will help the students to know their skills, interests, and personality that will help students in further career selection. This way they get comprehensive advice on career, courses and jobs that enable them to make a proper and informed choice. It's important to prepare them for life after school and what to expect in the different fields they might opt for and help them explore the world and figure out what they want to do
                            <li><b>It helps students to get to know their educational goals</b></li>
                            Counselors serve as an important part of the complete team that addresses the educational goals and needs of their students. They help prepare students for the academic, career, and social challenges through relating their school success with the potential success of their future lives.
                            <li><b>Help students cope with the challenges they are facing alongside academics</b></li>
                            Students can come to them voluntarily to seek advice and discuss their problems personally and can offer advice to students at crucial turning points in their lives. It encourages students to have these types of conversations with their parents and peers, as well as being a sympathetic ear for the same students to come to when necessary.
                            <li><b>Help students to gather resources suitable for them and provide them with feedback</b></li>
                            A good counselor can provide students with qualitative feedback regarding what thing he/she needs to improve. From interpersonal and soft skills to performing under stress and managing stress levels, a counselor can help students with all of that.
                            <li><b>Help students stay updated with changes</b></li>
                            Counselors help students stay up to date with changes in deadlines for certain courses to apply, any new opportunities that are coming up, any sort of internships they can do to support their college applications or to gain experience.
                        </ol>
                        <b>Ways to appropriately approach a mentor</b>
                        Once you’ve thought through your choice, you’re ready to ask someone to mentor you. Here’s how to do it.
                        <br></br>In your email/text:<br></br>
                        <ol>
                            <li><b>Schedule an initial conversation.</b></li>
                            Ask your potential mentor if he or she can make time for an hour meeting with you. You don’t want to be rushed, and you want plenty of time for the other person to ask you questions about your goals, etc.
                            <li><b>Clearly describe the guidance you’re seeking (The Ask).</b></li>
                            This is where that preliminary brainstorming on your part will help you articulate just what you have in mind. Describe what advice or guidance you are seeking and for what purpose. Is it to help you navigate your current department politics or are you seeking to apply to a different position? Are you thinking about going back to school and are not sure what area of study to focus on? Think about this and articulate up front what you are seeking.
                            <li><b>Confirm your willingness to do the necessary work and follow-through.</b></li>
                            There’s nothing more frustrating than mentoring someone who doesn’t do the work necessary to take advantage of advice, so you want to make it clear to your potential mentor that you’re ready to commit the time, energy and effort to make the most of their counsel (and time).
                            <li><b>Acknowledge and respect the individual’s time.</b></li>
                            Most people who are asked to become mentors are highly successful in their careers, which means they’re also very busy and much in demand. So it’s important for you to acknowledge that reality, and make it clear how much you appreciate their considering your request. This is also the way to provide a graceful “out,” letting the other person cite an overbooked schedule for declining your request.
                            <li><b>Bring up your mentee’s recent wins.</b></li>
                            Leading with a discussion of your mentee’s recent accomplishments can be a good place to start, says Thistle. It shows that you’ve been paying attention – and can open the door to a discussion of not only the IT professional’s strengths but also what he or she may have struggled with, in a way that’s comfortable.
                            <br></br>If you’re reaching out to someone with whom you have no connection, go for an introduction along with any commonalities, specific interests or discussion points. Try to make a quick connection to hopefully pique his or her curiosity and spark interest in meeting with you. We advise you to ask to meet them for coffee or a brief meeting in their office first so you can both get to know each other. Aim for 30 minutes for your initial meeting.
                            <br></br>Do not ask someone to be your mentor in your introductory email or in your first meeting. Like all relationships, building trust and rapport takes time. You may need to meet a few times and get to know them, learn about their current career and goals before asking them to be your mentor.
                            <br></br><b>Note:</b> If you don’t hear from them, follow-up, but don’t hound him or her. Check-in two to three weeks after your initial contact, but after that, you need to assume he or she doesn’t have the time to meet you right now. It is time to focus on the other two or more on your list of potential mentors. Try to maintain a relationship (even if it’s one way) by sending notes or articles that may interest him or her once every six months just to check-in.
                        </ol>
                        <b>Example of approaching a mentor</b>
                        <br></br>Example of writing to an unknown contact (a referral, someone you have not spoken with or written to in the past):
                        <br></br>Dear Ali,
                        <br></br>My name is Sarim and I currently work for LUMS in the Development department. I’m new to this field, and have great respect for the work you have done, particularly in grassroots fundraising. I would like to hear your career story and ask a few questions about your career path. Could I ask for 30 minutes of your time over coffee or lunch to meet?
                        <br></br>I understand you are very busy and if 30 minutes is not possible perhaps a 15-minute phone conversation is more manageable.
                        <br></br>Please let me know a date and time that works best with your schedule. Thank you for your time and I look forward to speaking soon,
                        <br></br>Sarim

                    </Subtitle>
                    <Content>


                    </Content>

                </Panel>
                <img id="blog2" src={B2} alt="blog" />|
                <Panel>
                    <Subtitle>
                        <p><b>Last Minute Tips for your SAT Exam</b></p>
                        <p>There is a lot of hardwork that you need to do before the exam but right before the exam, you need not to worry about anything at all. Just do these 8 things and you will definitely feel better and ace your exam!</p>
                        <ol>
                            <li>GET ADEQUATE SLEEP!</li>
                            Go to bed early and wake up early. Our brains are at their best when they are well-rested.
                            <li>HAVE A HEALTHY BREAKFAST!</li>
                            Eat moderately before your exams; avoid a heavy meal. Research shows that pupils and students who eat healthy breakfast perform better in exams.
                            <li>STAY HYDRATED!</li>
                            One of the best ways to maximize your focus is to stay hydrated. Even mild dehydration can lead to tiredness, headaches, reduced alertness and diminished concentration.
                            <li>REVIEW SUMMARIES, RATHER THAN FULL NOTES!</li>
                            It is a good idea to read over summaries such as mind-maps, or one-page bullet point summaries of each topic. For math, revise all formulas, shortcuts, and conversions taught to you.
                            <li>GET TO THE CENTRE EARLY!</li>
                            This is to make sure that you have plenty of time to park, if necessary, check in and get settled before the test.
                            <li>READ EVERY QUESTION CLOSELY!</li>
                            Underline the parts being asked because often the simplest questions can be done wrong if not understood thoroughly.
                            <li>TIME YOURSELF!</li>
                            Start with the easiest questions that you can answer quickly. If you’re working on a tough problem and the answer is not immediately clear, don’t get stuck and waste valuable time. Move on to another problem, and come back to the harder problem later.
                            <li>PRAY!</li>
                            Pray for your success and peace of mind.
                        </ol>
                        <p>TEAM ‘KICKSAT PREPARATIONS’ WISHES YOU ALL THE BEST FOR YOUR EXAM!</p>
                    </Subtitle>
                </Panel>
                <img id="blog3" src={B3} alt="blog" />|
                <Panel>
                    <Subtitle>
                        <p>
                            A personal statement is a written document that typically highlights your personal, educational, and professional background and experiences. It is often required as part of a university application, and its purpose is to give the admissions committee a sense of who you are and why you are interested in pursuing a degree in a particular field.
                        </p>
                        <p><b> OBJECTIVE OF PERSONAL STATEMENT:</b></p>
                        <p>The primary purpose of a personal statement is to provide the admissions committee with a deeper understanding of the applicant beyond their academic profile. The statement should offer insight into the applicant's future aspirations, background, and unique characteristics. It should also demonstrate the applicant's level of commitment to their goals and ability to effectively communicate about themselves. Furthermore, the personal statement should showcase the applicant's writing skills and explain their motivations for seeking admission to the specific institution. By presenting a well-rounded and professional portrayal of the applicant, the personal statement aims to give the admissions committee a clear understanding of the individual's potential fit for the program.</p>
                        <p><b>PARTS OF THE PERSONAL STATEMENT:</b></p>
                        <p>Introduction: The introduction of your personal statement should grab the reader's attention and give them a sense of what to expect in the rest of the essay. You can start with a quote, a story, or an anecdote that reflects your personality and interests. You can also use the introduction to set the stage for the rest of your personal statement by outlining the main points you will be covering.</p>
                        Include experiences that show your versatile personality: Even if they are not directly related to the field of study, including experiences that show your diverse interests and passions can help demonstrate your versatility and well-roundedness. This can include hobbies, sports, or other activities that you are passionate about and that help define who you are as a person.
                        Interest in the field: In this section, you should explain why you are interested in pursuing a degree in a particular field and how it aligns with your career goals. You can discuss your previous experiences and how they have shaped your interests and passions. You can also mention any relevant coursework or research you have completed and how it has prepared you for a degree in this field.
                        Relevant experiences: In this section, you should highlight any hobbies, extra-curricular activities, or volunteer programs that demonstrate your passion for the field or relevant skills. These experiences can help show the admissions committee that you are dedicated and motivated, and that you have the skills and experiences necessary to succeed in your chosen field of study.
                        Show, don't tell: Use specific examples and anecdotes to illustrate your points and bring your personal statement to life. Instead of just saying that you are a hard worker, for example, you could describe a specific experience where you worked hard and achieved a particular goal. This will make your personal statement more engaging and memorable.
                        Edit and proofread carefully: Make sure to edit and proofread your personal statement carefully to ensure that it is well-written, free of errors, and easy to read. Have someone else review it for you as well, as a fresh set of eyes can often catch mistakes that you may have missed.
                        Keep it concise: Most universities have a word limit for personal statements, so make sure to stay within the specified range. Be selective about what you include, and focus on the most important and relevant experiences and points.
                        <p><b>BRAINSTORMING FOR YOUR PERSONAL STATEMENT</b></p>
                        To brainstorm ideas for your personal statement, you can try the following exercises:
                        <ol>
                            <li>Reflect on your experiences: Take some time to think about your experiences, both inside and outside of the classroom, and how they have shaped your interests and passions. What have you enjoyed doing most? What are you most proud of? What have you learned from these experiences?</li>
                            <li>Make a list of your strengths and skills: Think about what you are good at and what skills you have developed. How do these strengths and skills align with the field you are interested in studying?</li>
                            <li>Write down your career goals: What do you want to do after you graduate? How does a degree in this field fit into your long-term career plans?</li>
                        </ol>
                        By reflecting on your experiences, strengths, and career goals, you can start to get a sense of what to include in your personal statement and how to structure it. Remember to be authentic and genuine, and to clearly communicate your interest in and fit for the field of study you are applying to.
                    </Subtitle>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F3F3F3;
    img{
        width: 100%;
        max-width: 1000px;
        margin: auto;
    }
    ol{
        // padding: 10px;
    }
`;

const Panel = styled.div`
    width: 90%;
    max-width: 1200px;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
`;

const Content = styled.div`
    // width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 977px) {
        display: block;
    }
`;

// const TitleMobile = styled.h3`
//     margin: 0px;
//     font-size: 30px;
//     color: black;
//     font-weight: 800;
//     margin-top: 100px;
//     margin-bottom: 30px;
//     text-align: center;
//     @media screen and (max-width: 768px) {
//         font-size: 24px;
//     }
// `;

const Subtitle = styled(Title)`
    font-size: 15px;
    margin-top: 0px;
    // padding-left: 20px;
    // padding-right: 20px;
    font-weight: 300;
    max-width: 900px;
    text-align: justify;
    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
`;



export default Info;