import styled from "styled-components";
import { Title } from "./Overview";
import Slider from "react-slick";
import { GuideButton } from "./Guidebook";
import AidSat from "./AidSat";
import T6 from "../../assets/P6.png";

const IELTSInfo = () => {

    var testimonals = [
        {
            message: "SAT classes by Kicksat Preparations helped me a lot to achieve my SAT goal and I scored 1330 marks. Tricks and formulas learned and practiced in class with unique techniques were very helpful during the class. These classes taught me a lot in less time and I got to do my work with maximum productivity. Thanks to the amazing team for their hard work!",
            name: "Muhammad Haroon, Student (Now an Instructor at Kicksat Preparations)",
        },
        {
            message: "For someone who had no idea on how to even begin preparing for the SAT, Kicksat Preparations played a great role in helping me prepare for the exam, The classes were free of cost and we had great teachers like Danish and Rohan to learn from. There was a friendly environment in the classroom and everyone participated in the lectures. It was really nice to learn new and easy-to-use tricks about the exam which the books don't usually have. To sum it up, I learned a lot during the session, which led to me scoring 1380/1600 on my first attempt.",
            name: "Muhammad Hani, Student",
        },
        {
            message: "I have been a part of Kicksat Preparations since almost a year now. They helped me prepare for my first ever SAT exam in 2021 and now as I am applying to Lums, they're helping me with my further academic journey. The one on one teacher to student experience is commendable and  I have had great learning experiences from both the teachers.",
            name: "Rameen Safdar, Student",
        },
        {
            message: "Kicksat Preparations had incredibly interactive sessions where instructors helped you to solve questions by yourself through discourse rather than directly telling you the answer. This session helped me remain consistent with my SAT practice with weekly homework and practice tests. The instructors were extremely helpful with SAT tricks which I would never have thought off by myself. They taught us the syllabus and cemented our concepts with actual past papers. Overall I can say with confidence that Kicksat Preparations holds the recipe to ace the SAT",
            name: "Rayan Matin Dar, Student from Nov 2022",
        },
        // {
        //     message: "When I first started to study for the SAT, I thought there was no way I could ever ace it. There was no one to assist me, and the Concepts were completely new. When a friend told me about Kicksat Preparations, I decided to take advantage of the chance. I requested financial aid because the session is not free and I couldn't pay the fees. Thanks to the management, I received a 100% scholarship. It was an incredible experience when I initially started taking classes. I have never taken such fantastic online lessons before because online education is not very effective. However, the professors were quite encouraging; they often checked on each student's comprehension and repeated themselves if there were any misunderstandings. There were knowledgeable and skilled teachers who made it simple for us to learn each idea. I am now very confident about my SAT exam after taking classes from Kicksat. Kicksat and team, thank you so much for making this process so simple for me.",
        //     name: "Anonymous, Student from Nov 2022",
        // },
        {
            message: "My experience with Kicksat has been good so far. The instructors made it easy to understand the content and were open to discussions and answering doubts. The assignments and weekly tests have helped in keeping a check on overall progress and improving scores through practice.",
            name: "Eisha Ahmed, Student from Oct 2022",
        },
        {
            message: "To be honest, from my perspective regarding kicksat is k I found this digital tool to be very crucial for those who want to prepare themselves for the best business schools of Pakistan and foreign as well. The teachers are obviously amazing in terms of study, energy, and very enthusiastic, their good behaviors and achievements, professional, caring and we'll organized. I found curriculum matchless and very helpful in terms of cracking SAT. Great Team - Great Academy",
            name: "M. Tahir Mahar, Student from Oct 2022",
        },
        {
            message: "It's really an honor and great opportunity for me to be the part of kicksat prepartion. I was really worried about my SAT test but now I have gain confidence after preparing with kicksat. Live sessions, practice test and the material provided by kicksat is really helpful. I wasn't really good at maths,but after joining kicksat preparation I have learned so many basic techniques and formals.Now I am easily eligible of solving so many maths problems by my own. Thankyou kicksat.",
            name: "Umama Tariq, Student from Nov 2022",
        },
    ]

    var settings = {
        dots: false,
        infinite: true,
        speed: 250,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        pauseOnHover: false,
    };

    function alert1() {
        alert("Request your Self-Paced (Recorded) IELTS & TOEFL Course by sending an email at danish.khan@kicksatprep.org or WhatsApp Message at 03314973171 (International: +923314973171)");
    }

    function alert2() {
        alert("Request your Online Live (One-on-One) IELTS & TOEFL Course by sending an email at danish.khan@kicksatprep.org or WhatsApp Message at 03314973171 (International: +923314973171)");
    }


    return (
        <>
            <Main>
                <Container>
                    <Panel className="block">
                        <TitleMobile>ONLINE IELTS & TOEFL PREPARATION</TitleMobile>
                        <Subtitle>
                            <p>Ready to conquer the IELTS or TOEFL and achieve your dreams? Look no further than Kicksat Preparations!</p>
                            <p>Our comprehensive online IELTS and TOEFL preparation courses are tailored to help you succeed on test day and reach your language proficiency goals. Our experienced instructors will guide you through every section of the exams and provide personalized practice materials to hone your skills. You can either enroll in our upcoming online live session to interact with the instructors live or with the convenience of online learning, you can access our sessions from anywhere and at any time.</p>
                            <p>Don't let language barriers hold you back - join the ranks of successful Kicksat Preparations students today and let us help you achieve your dreams!</p>
                        </Subtitle>
                    </Panel>
                </Container>
                <ContainerBlue>
                    <Panel className="block">
                        <TitleMobileW>UPCOMING LIVE PREPARATION SESSION</TitleMobileW>
                        <Subtitle>
                            <p><StatMid>Starting From: </StatMid><Stat>28th January</Stat><StatMid> 2023</StatMid></p>
                            <p><StatMid>Class Timings: </StatMid><Stat>Fridays: 07:00 PM to 09:00 PM</Stat></p>
                            <p><StatMid>Saturday & Sunday: </StatMid><Stat> 02:00 PM to 04:00 PM</Stat></p>
                            <p><StatMid>Total Duration: </StatMid><Stat>4 Weeks</Stat></p>
                            <br></br>
                            <p>More details about what is offered and registration are available at the bottom of this page. </p>
                        </Subtitle>
                    </Panel>
                </ContainerBlue>
                <ContainerWhite>
                    <PanelHalf>
                        <TitleMobile>KICKSAT PREPARATIONS LEARNING EXPERIENCE:</TitleMobile>
                        <Subtitle>
                            <ul>
                                <li className="special">
                                    Interactive live and recorded classes
                                </li>
                                <li className="special">
                                    Practice exams and quizzes with in-depth explanations
                                </li>
                                <li className="special">
                                    Online and offline support for all your questions
                                </li>
                                <li className="special">
                                    Detailed class notes and study materials
                                </li>
                                <li className="special">
                                    Personalized instructions
                                </li>
                                <li className="special">
                                    Convenient and flexible schedule
                                </li>
                            </ul>
                        </Subtitle>
                    </PanelHalf>
                    <PanelHalf>
                        <TitleMobile>HEAR FROM OUR STUDENTS</TitleMobile>
                        <Slider {...settings}>
                            {
                                testimonals?.map(item => (
                                    <Holder>
                                        <Box>
                                            <p>"{item.message}"</p>
                                            <p className="author">- {item.name}</p>
                                        </Box>
                                    </Holder>
                                ))
                            }

                        </Slider>
                    </PanelHalf>
                </ContainerWhite>
                <Container>
                    <TitleMobile className="toppad">CHOOSE YOUR IELTS & TOEFL PREPARATION SESSION:</TitleMobile>
                    <Panel className="pricing">
                        <ThirdPanel>
                            <TitleMobile className="special">ONLINE LIVE<br></br>(GROUP)</TitleMobile>
                            <Price>25,000 RS</Price>
                            <TitleMobile className="specialaid">(Financial Aid Available)</TitleMobile>
                            <Subtitle>
                                <ul>
                                    <li>
                                        24 Hours of Live IELTS & TOEFL Coaching
                                    </li>
                                    <li>
                                        Recorded Lectures Available
                                    </li>
                                    <li>
                                        Weekly Homeworks
                                    </li>
                                    <li>
                                        Online Study Material
                                    </li>
                                    <li>
                                        5 Full Length Practice Tests Completed
                                    </li>
                                    <li>
                                        Discussion Sessions with Instructors
                                    </li>
                                    <li>
                                        Availability of Instructors over WhatsApp
                                    </li>
                                    <li>
                                        One-on-One Office Hours for those who need it
                                    </li>
                                    <li>
                                        Self-study plan beyond live preparation
                                    </li>
                                    <li>
                                        Life-time access to Kicksat Community & Founders for any academic and professional support
                                    </li>
                                </ul>
                            </Subtitle>
                            <GuideButton className="GuideButton" href="https://forms.gle/gvf4dgaf5THUQjCE6">REGISTER NOW</GuideButton>
                        </ThirdPanel>
                        <ThirdPanel>
                            <TitleMobile className="special">SELF-PACED<br></br>(RECORDED)</TitleMobile>
                            <Price>10,000 RS</Price>
                            <TitleMobile className="specialaid">(Financial Aid NOT Available)</TitleMobile>
                            <Subtitle>
                                <ul>
                                    <li>
                                        24 hours of Recorded IELTS & TOEFL Lectures
                                    </li>
                                    <li>
                                        Session-wise homework
                                    </li>
                                    <li>
                                        Online Study Material
                                    </li>
                                    <li>
                                        5 Full Length Practice Tests Completed
                                    </li>
                                    <li>
                                        Discussion Sessions with Instructors
                                    </li>
                                    <li>
                                        Availability of Instructors over WhatsApp
                                    </li>
                                    <li>
                                        One-on-One Office Hours for those who need it
                                    </li>
                                    <li>
                                        Self-study plan beyond live preparation
                                    </li>
                                    <li>
                                        Life-time access to Kicksat Community & Founders for any academic and professional support
                                    </li>
                                </ul>
                            </Subtitle>
                            <GuideButton className="GuideButton" onClick={alert1}>REQUEST NOW</GuideButton>
                        </ThirdPanel>
                        <ThirdPanel>
                            <TitleMobile className="special">ONLINE LIVE<br></br>(ONE-ON-ONE)</TitleMobile>
                            <Price>45,000 RS</Price>
                            <TitleMobile className="specialaid">(Financial Aid NOT Available)</TitleMobile>
                            <Subtitle>
                                <ul>
                                    <li>
                                        20 hours of 1-1 IELTS & TOEFL Coaching
                                    </li>
                                    <li>
                                        Complete IELTS & TOEFL Preparation Live
                                    </li>
                                    <li>
                                        Recorded Lectures Available
                                    </li>
                                    <li>
                                        Online Study Material
                                    </li>
                                    <li>
                                        5 Full Length Past Exams / Official Tests Completed
                                    </li>
                                    <li>
                                        Live Test Session
                                    </li>
                                    <li>
                                        Discussion Sessions with Instructors
                                    </li>
                                    <li>
                                        Availability of Instructors over WhatsApp
                                    </li>
                                    <li>
                                        Self-study plan beyond live preparation
                                    </li>
                                    <li>
                                        Life-time access to Kicksat Community & Founders for any academic and professional support
                                    </li>
                                </ul>
                            </Subtitle>
                            <GuideButton className="GuideButton" onClick={alert2}>REQUEST NOW</GuideButton>
                        </ThirdPanel>
                    </Panel>
                </Container>
                <AidSat>
                </AidSat>
                <TitleMobile className="grey">MEET YOUR INSTRUCTORS</TitleMobile>
                <ContainerWhite className="grey">
                    <PanelHalf className="full">
                        <ImageBox>
                            <img src={T6} alt="Founder" />
                        </ImageBox>
                        <TitleMobile>MUHAMMAD AHSAN SHAHAB</TitleMobile>
                        <Subtitle>
                            <p className="title">
                                English as Second Language (ESL) Expert
                            </p>
                            <p>
                                Muhammad Shahab Ahsan is a highly qualified and experienced language instructor, with a strong background in teaching English as a Second Language (ESL). He is a certified Cambridge Instructor and Teacher Trainer, and has expertise in preparing students for various ESL exams, including IELTS, TOEFL, OET, PTE, Duolingo, and Cambridge FCE/CAE. In addition to his experience as an ESL tutor and trainer, Muhammad has also served as an Adjunct Faculty at The City University of New York, where he led trainees to become fully qualified and responsible TEFL Teachers, demonstrated good teaching practices, and maintained the professionalism and integrity of the course. Currently, Muhammad holds the position of Deputy Manager Trainer at The City School Head Office, where he is responsible for overseeing the training and development of language instructors and ensuring the highest standards of education are maintained.
                            </p>
                            <GuideButton className="linked" href="https://www.linkedin.com/in/muhammad-ahsan-shahab-076427169/">
                                Connect with Ahsan Shahab over LinkedIn
                            </GuideButton>
                        </Subtitle>
                    </PanelHalf>
                </ContainerWhite>
            </Main>
        </>
    );
}

const Main = styled.div`
    .block{
        display: flex;
        flex-direction: column;
    }
    .GuideButton{
        font-size: 17px;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: 10px;
    }
    .linked{
        width: 280px;
        font-size: 12px;
        font-weight: 600;
        margin: auto;
    }
    .full{
        wdith: 100%;
        max-width: 1000px;
    }
    .grey{
        background-color: #F3F3F3;
    }
`;

const Price = styled.h3`
    margin: 16px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: 700;
    background-color: black;
    font-color: white;
    padding: 0px 10px;
    width: 105px;
    @media screen and (max-width: 1100px) {
    font-size: 20px;
    }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F3F3F3;
    .pricing{
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-auto-flow: column;
        column-gap: 20px;
        max-width: 1000px;
        grid-template-columns: auto auto auto;
        @media screen and (max-width: 1069px) {
            display: block;
        }
    }
    .toppad{
        padding-top: 20px;
    }
`;

const ImageBox = styled.div`
    width: 200px;  
    padding: 10px;  
    // padding-bottom: 30px;
    justify-content: center;
    margin: auto;
    img{
        width: 160px;
        border-radius: 100%;
        // height: 100px;
        margin: auto;
        display: block;
    }
    .special{
        width: 180px;
    }
`;


const ThirdPanel = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    border-style: solid;
    border-color: rgb(0,176,182);
    background-color: white;
    // padding: 10px;
    // margin: 0px 20px;
    .special{
        margin-bottom: -10px;
        font-size: 25px;
    }
    .specialaid{
        font-size: 13px;
        margin: 0px;
        text-align: center;
    }
    .specialhead{
        font-size: 13px;
    }
    @media screen and (max-width: 1069px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    ul{
        // margin: 0px;
        font-size: 15px;
        list-style-position: outside;
        padding-left: 15px;
        @media screen and (max-width: 1069px) {
            font-size: 13px;
        }
    }
`;

const ContainerWhite = styled.div`
    width: calc(100% - 20px);;
    display: flex;
    justify-content: center;
    margin: 10px;
    margin-top: 0px;
    background-color: #FFFFFF;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const Holder = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center;
    margin-bottom: 40px;
`;

const Box = styled.div`
    width: 320px;
    height: 400px;
    padding: 20px;
    background: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-bottom: 20px;
    p{
        display: block;
        margin: 0px;
        padding-bottom: 5px;
        color: black;
        font-size: 15px;
        @media screen and (max-width: 768px) {
        font-size: 13.5px;
    }
    }
    .author{
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }
`;


const Stat = styled.span`
    font-size: 20px;
    font-weight: 700;
    color: white;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`;

const StatMid = styled(Stat)`
    font-weight: 200;
`;

const ContainerBlue = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    p{
        color:white;
        font-size: 17px;
    }
`;


const Panel = styled.div`
    width: 100%;
    // max-width: 1200px;
    align-items: center;
    margin-bottom: 50px;
    @media screen and (max-width: 1069px) {
        display: block;
    }
`;

const PanelHalf = styled.div`
    width: 40%;
    max-width: 500px;
    // padding: 0px 20px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // margin-bottom: 50px;
    @media screen and (max-width: 768px) {
        width: 100%;
        // padding: 0px 20px;
        margin-left: auto;
        margin-right: auto;
    }
`;


const TitleMobile = styled.h3`
    margin: 0px;
    font-size: 30px;
    color: black;
    font-weight: 800;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

const TitleMobileW = styled.h3`
    margin: 0px;
    font-size: 30px;
    color: white;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;




const Subtitle = styled(Title)`
    padding: 0px 20px;
    font-size: 18px;
    margin-top: 0px;
    font-weight: 300;
    font-weight: 400;
    max-width: 900px;
    text-align: justify;
    margin-bottom: 10px;
    p{
        font-size: 16px;
    }
    .special{
        font-size: 16px;
        padding-top: 20px;
    }
    .title{
        font-size: 18px;
        font-weight: 700;
    }
`;



export default IELTSInfo;