import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";
import Aid from "./Components/Aid";
import Header from "./Components/Header";
import Hero from "./Components/Hero";
import Offering from "./Components/Offering";
import Overview from "./Components/Overview";
import Stats from "./Components/Stats";
import Testimonial from "./Components/Testimonials";
import Vision from "./Components/Vision";
import Why from "./Components/Why";
import Team from "./Components/Team";
import Guidebook from "./Components/Guidebook";
import Partners from "./Components/Partners";
import MyFooter from "./Components/Footers";
import Stories from "./Components/Stories";

const Home = () =>  {

    return (
        <>
            <Container>
                <Header/>
                <Hero/>
                <Vision/>
                <Overview/>
                <Stats/>
                <Aid/>
                <Why/>
                <Offering/>
                <Testimonial/>
                <Team/>
                <Guidebook/>
                <Partners/>
                <Stories/>
                <MyFooter/>
            </Container>
        </>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: white;
`;



export default Home;