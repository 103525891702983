import styled from "styled-components";
import Why1 from '../../assets/WhyKS1.png';
import Why2 from '../../assets/WhyKS2.png';
import Why3 from '../../assets/WhyKS3.png';
import Why4 from '../../assets/WhyKS4.png';

const Why = () =>  {

    return (
        <>
            <Container>
                <Panel>
                    <Title>WHY KICKSAT PREPARATIONS</Title>
                    <Row>
                        <Box>
                            <img src={Why1} alt=""/>
                            <Line/>
                            <p>Personalized coaching, mentorship and support beyond the Test Preparation</p>
                        </Box>
                        <RightBox>
                            <img src={Why2} alt=""/>
                            <Line/>
                            <p>Quality content with options of both, live and recorded sessions</p>
                        </RightBox>
                    </Row>
                    <Row>
                        <Box>
                            <img src={Why3} alt=""/>
                            <Line/>
                            <p>Free mentorship workshops and online practice material</p>
                        </Box>
                        <RightBox>
                            <img src={Why4} alt=""/>
                            <Line/>
                            <p>Team and Instructors are directly accessible for student support</p>
                        </RightBox>
                    </Row>
                    

                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    padding: 20px;
`;

const Row = styled.div`
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const Box = styled.div`
    width: 40%;
    padding: 20px;
    color: black;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 18px;
    // text-align: justify;
    img{
        height: 60px;
        width: auto;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 90%;
    }
`;

const RightBox = styled(Box)`
    // align-items: flex-end;
    // text-align: right;
`;

const Line = styled.div`
    margin-top: -6;
    width: 200px;
    height: 10px;
    background-image: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
`;

const Title = styled.h3`
    margin: 0px;
    margin-top: 30px;
    margin-bottom: 40px;
    width: auto;
    font-size: 30px;
    color: black;
    font-weight: 900;
    text-style: italic;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
`;

export default Why;