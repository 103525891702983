import styled from "styled-components";
import { Title } from "./Overview";

const Offering = () => {

    return (
        <>
            <Container>
                <Panel>
                    <Title>OUR OFFERINGS</Title>
                    <Content>
                        <Box>
                            <TextBox>
                                <Name>SAT/LCAT PREP</Name>
                                <Subtitle>LCAT = LUMS Common <br></br> Admission Test</Subtitle>
                            </TextBox>
                            <Button href="/sat">REGISTER NOW</Button>
                        </Box>
                        <Box>
                            <TextBox>
                                <Name>GRE PREP</Name>
                            </TextBox>
                            <Button href="gre">REGISTER NOW</Button>
                        </Box>
                        <Box>
                            <TextBox>
                                <Name>IELTS & TOEFL PREP</Name>
                            </TextBox>
                            <Button href="/ielts-toefl">REGISTER NOW</Button>
                        </Box>
                        {/* <Box>
                            <Name>PRACTICE TESTS</Name>
                            <Button>REGISTER NOW</Button>
                        </Box> */}

                    </Content>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(243, 243, 243);

`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px) {
        display: block;
    }
`;

const TextBox = styled.div`
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
        margin-top: 0px;   
    }
`;

const Box = styled.div`
    width: 22%;
    height: 300px;
    margin: 20px;
    background-image: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 40px;
    .bold{
        font-weight: 600;
    }
    @media screen and (max-width: 1000px) {
        width: 90%;
        height: 250px;
        
    }
`;

const Name = styled.h3`
    margin: 0px;
    font-size: 24px;
    font-weight: 800;
    color: white;
    margin-top: 50px;
`;

const Subtitle = styled(Name)`
    font-size: 14px;
    margin-top: 0px;
    font-weight: 300;
`;

const Button = styled.a`
    text-decoration: none;
    color: rgb(37, 150, 190);
    font-size: 18px;
    font-weight: 700;
    background: white;
    padding: 12px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    margin-bottom: 20px;
    
    text-transform: uppercase;
    :hover{
        box-shadow: 0px 8px 10px 0px rgb(0 0 0 / 34%);
    }
`


export default Offering;