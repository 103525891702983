import styled from "styled-components";
import Logo from '../../assets/logo.png';
import WhatsAppLogo from '../../assets/whatsapp.png';

const Header = () => {

    return (
        <>

            <Navbar>
                <NavBox>
                    <LogoBox href="/">
                        <img src={Logo} alt="Logo" />
                    </LogoBox>
                    <Menu>
                        <NavItem href="about">About</NavItem>
                        <NavItem href="sat">SAT</NavItem>
                        <NavItem href="gre">GRE</NavItem>
                        <NavItem href="/ielts-toefl">IELTS/TOFEL</NavItem>
                        <NavItem href="blogs">Blog</NavItem>
                        <NavItem href="team">Team</NavItem>
                    </Menu>
                    <MobileMenu>
                        <div class="dropdown">
                            <button class="dropbtn"><Bars /><Bars /><Bars /></button>
                            <div class="dropdown-content">
                                <NavItemMobile href="about">About</NavItemMobile>
                                <NavItemMobile href="sat">SAT</NavItemMobile>
                                <NavItemMobile href="gre">GRE</NavItemMobile>
                                <NavItemMobile href="/ielts-toefl">IELTS/TOFEL</NavItemMobile>
                                <NavItemMobile href="blogs">Blog</NavItemMobile>
                                <NavItemMobile href="team">Team</NavItemMobile>
                            </div>
                        </div>

                    </MobileMenu>
                </NavBox>
            </Navbar>
            <WhatsApp href="https://wa.me/923314973171">
                <p>Get in Touch</p>
                <img src={WhatsAppLogo} alt="Logo" />
            </WhatsApp>
        </>
    );
}

const WhatsApp = styled.a`
    text-decoration: none;
    color: #343838;
    font-size: 15px;
    font-weight: 500;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    display: flex;
    background-color: white;
    padding: 0px 10px;
    // box-shadow: 0px 0px 20px 20px white;
    border-radius: 25px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    :hover{
        box-shadow: 0px 12px 14px 0px rgb(0 0 0 / 34%);
    }
    img{
        width: 30px;
        height: 30px;
        padding-top: 10px;
        padding-left: 5px;
    }
`;

const Navbar = styled.div`
    width: calc(100% - 20px);
    // background-image: linear-gradient(to right, rgb(0, 176, 182), rgb(8, 204, 196));
    background: #ffffffed;
    height: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0px;
    padding: 10px;
    box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 44%);
    z-index: 10;

    .dropbtn {
        padding: 16px;
        font-size: 16px;
        border: none;
        background:transparent; 
      }
      .dropdown {
        position: relative;
        display: inline-block;
      }
      .dropdown-content {
        display: none;
        position: fixed;
        right: 0;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      }
      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      .dropdown-content a:hover {background-color: #ddd;}
      .dropdown:hover .dropdown-content {display: block;}
      .dropdown:hover .dropbtn {}
`;

const NavBox = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoBox = styled.a`
    width: 200px;
    height: 100%;
    margin-left: 20px;
    display: flex;
    align-items: center;
    img{
        width: 200px;
        height: auto;
        @media screen and (max-width: 768px) {
            width: 150px;
            height: auto;
        }
    }

`;

const Menu = styled.div`
@media screen and (max-width: 768px) {
    display: none;
}
`;

const Bars = styled.div`
    width: 30px;
    height: 3px;
    background-color: #343838;
    margin: 5px 0;
`;


const MobileMenu = styled.div`
@media screen and (min-width: 769px) {
    display: none;
}
`;


const NavItem = styled.a`
    text-decoration: none;
    color: #343838;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 25px;
    cursor: pointer;
    :hover{
        font-weight: 800;
    }
    transition: all 200ms ease-in-out;
`;

const NavItemMobile = styled.a`
    text-decoration: none;
    color: #343838;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 0px;
    cursor: pointer;
    :hover{
        font-weight: 800;
    }
    transition: all 200ms ease-in-out;
`;


export default Header;