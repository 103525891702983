import styled from "styled-components";
import { Title } from "./Overview";
import T1 from "../../assets/T1.jpg";
import T2 from "../../assets/T2.jpg";
import T3 from "../../assets/T3.jpg";
import T4 from "../../assets/T4.jpg";
import T5 from "../../assets/T5.jpg";
import LinkedIn from "../../assets/linkedin.svg";



const Team = () => {

    const members = [
        {
            name: "FIZZA AFREEN",
            position: "Manager",
            image: T1,
            linkedin: "https://www.linkedin.com/in/fizza-afreen-5a7619138/",
        },
        {
            name: "ROHAN HUSSAIN",
            position: "Co-Founder & Math Instructor",
            image: T2,
            linkedin: "https://www.linkedin.com/in/rohan-hussain/",
        },
        {
            name: "DANISH KHAN",
            position: "Founder & Director English Instructor",
            image: T3,
            linkedin: "https://www.linkedin.com/in/danishkhandani/",
        },
        {
            name: "AROOBA ALI",
            position: "Marketing Lead",
            image: T4,
            linkedin: "https://www.linkedin.com/in/aroobaali/",
        },
        {
            name: "M. HAROON",
            position: "Math Instructor",
            image: T5,
            linkedin: "https://www.linkedin.com/in/haroon1m/",
        },
    ]


    return (
        <>
            <Container>
                <Panel>
                    <Title>TEAM</Title>
                    <Subtitle>We are a team of young professionals, with years of Global Experience in the Education Sector, passionate to play our role in curating your journey to a successful life ahead.</Subtitle>
                    <Content>
                        {
                            members.map((person, index) => {
                                return (
                                    <ImageBox>
                                        <img className={index === 2 ? 'special' : ''} src={person.image} alt="" />
                                    </ImageBox>
                                )
                            })
                        }
                    </Content>
                    <Content>
                        {
                            members.map((person, index) => {
                                return (
                                    <Box>


                                        <Name>{person.name}</Name>
                                        <p>{person.position}</p>

                                        <a href={person.linkedin}><img src={LinkedIn} alt="" /></a>

                                    </Box>
                                )
                            })
                        }
                    </Content>
                    <ContentMobile>
                        {
                            members.map((person, index) => {
                                return (
                                    <ImageBox>
                                        <img className={index === 2 ? 'special' : ''} src={person.image} alt="" />

                                        <Box>
                                            <Name>{person.name}</Name>
                                            <p>{person.position}</p>

                                            <a href={person.linkedin}><img src={LinkedIn} alt="" /></a>
                                        </Box>

                                    </ImageBox>
                                )
                            })
                        }
                    </ContentMobile>
                    <Button href="team">Meet the Team</Button>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F3F3F3;
    overflow: hidden;
`;

const Button = styled.a`
    text-decoration: none;
    margin-top: 30px;
    color: white;
    width: 170px;
    height: 35px;
    /* display: block; */
    background-image: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    :hover{
        box-shadow: 0px 12px 14px 0px rgb(0 0 0 / 34%);
    }
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    
`;

const Content = styled.div`
    // width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1069px) {
        display: none;
    }
`;

const ContentMobile = styled.div`
    // width: 100%;
    color: black;
    display: block;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 1070px) {
        display: none;
    }
`;

const ImageBox = styled.div`
    width: 200px;  
    padding: 10px;  
    display: block;
    padding-bottom: 30px;
    justify-content: center;
    img{
        width: 160px;
        border-radius: 100%;
        // height: 100px;
        margin: auto;
        display: block;
    }
    .special{
        width: 180px;
    }
`;

const Box = styled.div`
    width: 200px;
    height: 100px;
    padding: 0px;
    padding-bottom: 20px;
    
    background: rgb(243, 243, 243);
    
    text-align: center;
    p{
        display: block;
        margin: 0px;
        height: 60px;
        margin-bottom: -7px;
    }
    img{
        height: 32px;
    }
`;

const Name = styled.h3`
    margin: 0px;
    font-size: 22px;
    font-weight: 800;
    color: black;
    // margin-top: 50px;
    
`;

const Subtitle = styled(Title)`
    font-size: 20px;
    margin-top: -10px;
    font-weight: 300;
    max-width: 900px;
`;



export default Team;