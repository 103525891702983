import styled from "styled-components";
import Slider from "react-slick";


// import People from '../../assets/people';

const Stories = () => {
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
        return Object.values(images);
    }

    const People = importAll(require.context('../../assets/stories', false, /\.(png|jpe?g|svg)$/));

    // const People = require.context('../../assets/people', false, /\.(png|jpe?g|svg)$/);
    // console.log(People)
    var settings = {
        dots: false,
        infinite: true,
        speed: 0,
        autoplaySpeed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    function alert1() {
        alert("Reach out to us by sending an email at danish.khan@kicksatprep.org or WhatsApp Message at 03314973171 (International: +923314973171)");
    }

    return (
        <>
            <ContainerLimit>
                <Container>
                    <Panel>
                        <Title>SUCCESS STORIES</Title>
                        <Subtitle>
                            BECOME THE
                            NEXT SUCCESS STORY OF
                            KICKSAT PREPARATIONS
                        </Subtitle>
                        <Button onClick={alert1}>
                            REGISTER NOW
                        </Button>
                        <Subtitle className="detail">
                            *You will hear back directly from our instructors
                        </Subtitle>
                    </Panel>
                    <PanelPic>
                        <div>
                            <Slider {...settings}>
                                {
                                    People?.map(person => (
                                        <Image>
                                            <img src={person} alt="" />
                                        </Image>



                                    ))
                                }

                            </Slider>
                        </div>
                    </PanelPic>
                </Container>
            </ContainerLimit>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    background: #F2F2F2;
    overflow: hidden;
    @media screen and (max-width: 1000px) {
        display: block;
    }  
`;

const ContainerLimit = styled.div`
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    background: #F2F2F2;
    overflow: hidden;
    @media screen and (max-width: 1000px) {
        display: block;
    }  
`;

const Panel = styled.div`
    width: 300px;
    // height: 300px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // padding: 20px;
    img{
        width: 100%;
        @media screen and (max-width: 1000px) {
            width: 240px;
        }
    }
    .detail{
        font-size: 15px;
    }
    @media screen and (max-width: 1000px) {
        margin: auto;
    }
`;
const PanelPic = styled.div`
    width: 500px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 20px;
    img{
        width: 100%;
        @media screen and (max-width: 1000px) {
            width: 90vw;
        }
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const Image = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center;
    margin-bottom: 40px;
`;


const Title = styled.h3`
    margin: 0px;
    font-size: 24px;
    color: black;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: left;
`;

const Subtitle = styled.h3`
    margin: 0px;
    font-size: 24px;
    color: black;
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: left;
`;

const Button = styled.a`
    text-decoration: none;
    color: white;
    width: 130px;
    height: 40px;
    /* display: block; */
    background-image: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    :hover{
        box-shadow: 0px 12px 14px 0px rgb(0 0 0 / 34%);
    }
`;

export default Stories;