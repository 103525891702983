import styled from "styled-components";
import Rocket from "../../assets/vision.png";
import Wheel from "../../assets/mission.png";
import { GuideButton } from "./Guidebook";

const Vision = () => {

    return (
        <>
            <Container>
                <InfoPanel>
                    <Box>
                        <Panel>
                            <img src={Rocket} alt="rocket" />
                            <Title>VISION</Title>
                            <Detail>To bridge the gaps to make standardized testing accessible and affordable to all.</Detail>
                        </Panel>
                        <Panel>
                            <img src={Wheel} alt="wheel" />
                            <Title>MISSION</Title>
                            <Detail>To facilitate a journey to bigger academic dreams for more than 10,000 Pakistani students by the end of 2025.</Detail>
                        </Panel>
                    </Box>
                    <VisionButton href="about">LEARN MORE ABOUT KICKSAT</VisionButton>
                </InfoPanel>

            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const InfoPanel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Box = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Panel = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    img{
        margin-top: 30px;
        width: 100px;
        height: auto;
    }
    @media screen and (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
`;

const VisionButton = styled(GuideButton)`
    width: 330px;
    margin-bottom: 40px;
    font-size: 20px;
    height: 40px;
    @media screen and (max-width: 768px) {
        width: 90%;
        font-size: 15px;
    }
`;

const Title = styled.h3`
    margin: 0px;
    width: fit-content;
    font-size: 30px;
    color: black;
    font-weight: 900;
    text-style: italic;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Detail = styled.p`
    font-size: 18px;
    color: black;
    margin-top: 0px;
`;

export default Vision;