import styled from "styled-components";
// import { Title } from "./Overview";
import Slider from "react-slick";



const Testimonial = () =>  {

    var testimonals = [
        {
            message: "SAT classes by Kicksat Preparations helped me a lot to achieve my SAT goal and I scored 1330 marks. Tricks and formulas learned and practiced in class with unique techniques were very helpful during the class. These classes taught me a lot in less time and I got to do my work with maximum productivity. Thanks to the amazing team for their hard work!",
            name: "Muhammad Haroon, Student (Now an Instructor at Kicksat Preparations)",
        },
        {
            message: "For someone who had no idea on how to even begin preparing for the SAT, Kicksat Preparations played a great role in helping me prepare for the exam, The classes were free of cost and we had great teachers like Danish and Rohan to learn from. There was a friendly environment in the classroom and everyone participated in the lectures. It was really nice to learn new and easy-to-use tricks about the exam which the books don't usually have. To sum it up, I learned a lot during the session, which led to me scoring 1380/1600 on my first attempt.",
            name: "Muhammad Hani, Student",
        },
        {
            message: "I have been a part of Kicksat Preparations since almost a year now. They helped me prepare for my first ever SAT exam in 2021 and now as I am applying to Lums, they're helping me with my further academic journey. The one on one teacher to student experience is commendable and  I have had great learning experiences from both the teachers.",
            name: "Rameen Safdar, Student",
        },
        {
            message: "Kicksat Preparations had incredibly interactive sessions where instructors helped you to solve questions by yourself through discourse rather than directly telling you the answer. This session helped me remain consistent with my SAT practice with weekly homework and practice tests. The instructors were extremely helpful with SAT tricks which I would never have thought off by myself. They taught us the syllabus and cemented our concepts with actual past papers. Overall I can say with confidence that Kicksat Preparations holds the recipe to ace the SAT ",
            name: "Rayan Matin Dar, Student from Nov 2022",
        },
        {
            message: "When I started my SAT preparation, I thought there was no way I could ever ace it and I had no money to get support from an academy. A friend told me about Kicksat Preparations and I requested 100% financial aid. After a few conversations, they offered me the session free of cost. I have never taken such fantastic online lessons before because online education is not very effective. The instructors often checked on each student's comprehension and repeated themselves if there were any misunderstandings. I am now very confident about my SAT exam after taking classes from Kicksat. Thank you so much for making this process so simple for me.",
            name: "Anonymous, Student from Nov 2022",
        },
        {
            message: "My experience with Kicksat has been good so far. The instructors made it easy to understand the content and were open to discussions and answering doubts. The assignments and weekly tests have helped in keeping a check on overall progress and improving scores through practice.",
            name: "Eisha Ahmed, Student from Oct 2022",
        },
        {
            message: "To be honest, from my perspective regarding kicksat is k I found this digital tool to be very crucial for those who want to prepare themselves for the best business schools of Pakistan and foreign as well. The teachers are obviously amazing in terms of study, energy, and very enthusiastic, their good behaviors and achievements, professional, caring and we'll organized. I found curriculum matchless and very helpful in terms of cracking SAT. Great Team - Great Academy",
            name: "M. Tahir Mahar, Student from Oct 2022",
        },
        {
            message: "It's really an honor and great opportunity for me to be the part of kicksat prepartion. I was really worried about my SAT test but now I have gain confidence after preparing with kicksat. Live sessions, practice test and the material provided by kicksat is really helpful. I wasn't really good at maths,but after joining kicksat preparation I have learned so many basic techniques and formals.Now I am easily eligible of solving so many maths problems by my own. Thankyou kicksat.",
            name: "Umama Tariq, Student from Nov 2022",
        },
    ]

    var settings = {
        dots: false,
        infinite: true,
        speed: 250,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        arrows: false,
        pauseOnHover: false,
        responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };


    return (
        <>
            <Container>
                <Panel>
                    <TitleMobile>TESTIMONIALS</TitleMobile>
                    <Content>
                        <Slider {...settings}>
                            {
                                testimonals?.map(item => (
                                    <Holder>
                                        <Box>
                                            <p>"{item.message}"</p>
                                            <p className="author">- {item.name}</p>
                                        </Box>
                                    </Holder>
                                ))
                            }
                            
                        </Slider>
                    </Content>
                </Panel>
            </Container>
        </>
    );
}

const TitleMobile = styled.h3`
    margin: 0px;
    font-size: 24px;
    color: black;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    
`;

const Holder = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center;
    margin-bottom: 40px;
`;

const Content = styled.div`
    width: 100%;
    color: black;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
`;

const Box = styled.div`
    width: 320px;
    height: 450px;
    padding: 20px;
    background: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-bottom: 20px;
    p{
        display: block;
        margin: 0px;
        padding-bottom: 5px;
        font-size: 15px;
    }
    .author{
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }
    @media screen and (max-width: 768px) {
        p{
            font-size: 14px;
        }
    }
`;



export default Testimonial;