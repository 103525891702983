import styled from "styled-components";
import HeroImage from '../../assets/hero.jpg';
const Hero = () =>  {

    return (
        <>
            <HeroSection>
                <HeroBG/>
                <HeroContent>
                    <HeroText>Kicking hurdles out of your way to achieve the Perfect Test Score!</HeroText>
                </HeroContent>
                <BottomHero>
                    <Container>
                        <HeroSubTitle>Start preparing for your next test today!</HeroSubTitle>
                        <ButtonBox>
                            <Button href='/SAT'>SAT®</Button>
                            <Button href="/gre">GRE®</Button>
                            <Button href="/ielts-toefl">IELTS®</Button>
                            <Button href="/ielts-toefl">TOEFL®</Button>
                            <Button href="blogs">& MORE</Button>
                        </ButtonBox>
                        
                    </Container>
                </BottomHero>
            </HeroSection>
        </>
    );
}

const HeroSection = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    
`;

const HeroBG = styled.div`
    width: 100%;
    height: 100vh;
    // width: 100vw;
    // height: 75vw;
    background-image: url(${HeroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: grayscale(1) brightness(0.5);
    position: absolute;
`;

const HeroContent = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
`;

const HeroText = styled.h1`
    margin: 0px;
    font-size: 6vw;
    font-weight: 800;
    margin-left: 20px;
    margin-right: 15px;
    margin-bottom: 50px;
    @media screen and (min-width: 1200px) {
        font-size: 60px;
`;

const BottomHero = styled.div`
    width: 100%;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
`;

const HeroSubTitle = styled.h3`
    margin: 0px;
    font-size: 32px;
    font-weight: 800;
    margin-left: 20px;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
`;

const ButtonBox = styled.div`
    position: relative;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const Button = styled.a`
    text-decoration: none;
    color: white;
    width: 100px;
    height: 40px;
    /* display: block; */
    background-image: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    :hover{
        box-shadow: 0px 12px 14px 0px rgb(0 0 0 / 34%);
    }
`;

export default Hero