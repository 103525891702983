import styled from "styled-components";
import { Title } from "./Overview";
import Financial from "../../assets/financial.png";

const Aid = () =>  {

    return (
        <>
            <Container>
                <Panel>
                    <Title>UP TO 100% FINANCIAL AID</Title>
                    <Content>
                        <DetailBox>
                            <p>Kicksat Preparations is proud to be the only institute which provides up to 100% Financial Aid for the students from Financially Challenging Background.</p>
                            <p><span className="bold">Best Part:</span> There is no maximum number of seats available; we conduct the sessions even if all students are on Financial Aid.</p>
                            <Button href="https://forms.gle/fFd5YT6MZECrZbYU8">Support Our Students</Button>
                        </DetailBox>
                        <ImageSection>
                            <img src={Financial} alt="coin"/>
                        </ImageSection>
                    </Content>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(243, 243, 243);

`;

const Panel = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DetailBox = styled.div`
    width: 50%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        font-size: 18px;
        color: black;
    }
    .bold{
        font-weight: 600;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Button = styled.a`
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    padding: 10px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    margin-top: 40px;
    text-transform: uppercase;
    :hover{
        box-shadow: 0px 8px 10px 0px rgb(0 0 0 / 34%);
    }
`

const ImageSection = styled.div`
    width: calc(40% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    // scale: 0.7;
    img{
        width: 100%;
        height: auto;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export default Aid;