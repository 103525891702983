import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import Home from './app/Home';
import About from './app/About';
import Sat from './app/Sat';
import TeamPage from './app/Team';
import GRE from './app/GRE';
import IELTS from './app/IELTS';
import BLOG from './app/BLOG';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path={'/'}
          element={<Home/>}
        /> 
         <Route
          path={'/about'}
          element={<About/>}
        />
        <Route
          path={'/sat'}
          element={<Sat/>}
        /> 
        <Route
          path={'/team'}
          element={<TeamPage/>}
        />
        <Route
          path={'/gre'}
          element={<GRE/>}
        /> 
        <Route
          path={'/ielts-toefl'}
          element={<IELTS/>}
        />
        <Route
          path={'/blogs'}
          element={<BLOG/>}
        /> 
      </Routes>
    </Router>
  );
}



export default App;
