import styled from "styled-components";
import Header from "./Components/Header";
import MyFooter from "./Components/Footers";
import SatInfo from "./Components/SatInfo";
import SATBanner from "./Components/SATBanner";
import Stories from "./Components/StoriesNow";

const Sat = () =>  {

    return (
        <>
            <Container>
                <Header/>
                <SATBanner/>
                <SatInfo/>
                <Stories className="grey"></Stories>
                <MyFooter/>
            </Container>
        </>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: white;
    .grey{
        background: #F3F3F3;
    }
`;



export default Sat;