import styled from "styled-components";
import Slider from "react-slick";


// import People from '../../assets/people';

const Overview = () =>  {
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
        return Object.values(images);
    }
      
    const People = importAll(require.context('../../assets/people', false, /\.(png|jpe?g|svg)$/));
      
    // const People = require.context('../../assets/people', false, /\.(png|jpe?g|svg)$/);
    // console.log(People)
    var settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 560,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

    return (
        <>
            <Container>
                <Panel>
                    <Title>MEET OUR STUDENTS</Title>
                    <div>
                    <Slider {...settings}>
                        {
                            People?.map(person => (
                                <Image>
                                    <img src={person} alt=""/>
                                </Image>
                                    
                                
                                
                            ))
                        }
                        
                    </Slider>
                    </div>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F3F3F3;
    overflow: hidden;
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 20px;
    img{
        width: 300px;
        @media screen and (max-width: 1000px) {
        width: 240px;
        }
    }
`;

const Image = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center;
    margin-bottom: 40px;
`;


export const Title = styled.h3`
    margin: 0px;
    font-size: 24px;
    color: black;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
`;

export default Overview;