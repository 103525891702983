import styled from "styled-components";
import { Title } from "./Overview";
import Financial from "../../assets/financial.svg";

const AidSat = () => {

    return (
        <>
            <Container>
                <Panel>
                    <Title>APPLYING FOR FINANCIAL AID:</Title>
                    <Content>
                        <DetailBox>
                            <p>Financial Aid is ONLY available to students with <span className="bold">demonstrated financial need</span>. If you believe you qualify, you can apply by completing the same registration form and write about your inability to pay the full fee. Please <span className="bold">provide details about your financial circumstances and specify the amount you are able to contribute</span> at the end of the form. </p>
                            <p>
                                Once you have submitted your registration form, send the following documents and / or details, in case documents are not available to our WhatsApp number: 03314973171 (International: +923314973171):
                                <ul>
                                    <li>Amount you can afford to pay for our session</li>
                                    <li>Educational expenses for you and your siblings (college & school fees etc.)</li>
                                    <li>Monthly Income of Parent(s) and Elder(s) at home (Breadwinners of the family) </li>
                                    <li>Latest Electricity Bill</li>
                                </ul>
                            </p>
                            <Button href="https://forms.gle/1mGp27cngDXnKNeX9">REGISTER NOW</Button>
                        </DetailBox>
                        <ImageSection>
                            <img src={Financial} alt="coin" />
                        </ImageSection>
                    </Content>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

`;

const Panel = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DetailBox = styled.div`
    width: 50%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        font-size: 18px;
        color: black;
    }
    .bold{
        font-weight: 600;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Button = styled.a`
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
    background: linear-gradient(to right,rgb(0,176,182),rgb(8,204,196));
    padding: 10px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    margin-top: 0px;
    text-transform: uppercase;
    :hover{
        box-shadow: 0px 8px 10px 0px rgb(0 0 0 / 34%);
    }
`

const ImageSection = styled.div`
    // width: calc(50% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    // scale: 0.7;
    img{
        width: 100%;
        height: auto;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export default AidSat;