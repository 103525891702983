import styled from "styled-components";
import { Title } from "./Overview";
import Guide1 from "../../assets/guide1.jpg";
import Guide2 from "../../assets/guide2.jpg";
import Guide3 from "../../assets/guide3.jpg";

import { Button } from "./Hero";



const Guidebook = () =>  {



    return (
        <>
            <Container>
                <Panel>
                    <TitleMobile>GUIDEBOOKS</TitleMobile>
                    <Subtitle>There are some questions that so many of our students ask, we have converted them into guidebooks. Read them all here!</Subtitle>
                    <Content>
                            
                        <ImageBox href="blogs">
                            <img src={Guide1} alt=""/>
                        </ImageBox>
                        <ImageBox href="blogs">
                            <img src={Guide2} alt=""/>
                        </ImageBox>
                        <ImageBox href="blogs#blog3">
                            <img src={Guide3} alt=""/>
                        </ImageBox>
                           
                    </Content>
                    <GuideButton href="blogs">READ MORE</GuideButton>
                  
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`;

const Content = styled.div`
    // width: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 977px) {
        display: block;
    }
`;

const TitleMobile = styled.h3`
    margin: 0px;
    font-size: 24px;
    color: black;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
`;

const ImageBox = styled.a`
    width: 295px;  
    padding: 10px;  
    display: flex;
    justify-content: center;
    img{
        width: 100%;
        
        // height: 100px;
    }
    .special{
        width: 180px;
    }
`;

export const GuideButton = styled(Button)`
    width: 240px;
    margin-top: 30px;
    font-size: 20px;
    width: 150px;
`;


const Subtitle = styled(Title)`
    font-size: 20px;
    margin-top: -10px;
    font-weight: 300;
    max-width: 900px;
`;



export default Guidebook;