import styled from "styled-components";
// import Logo from '../../assets/logoGrey.png';
const MyFooter = () => {

    return (
        <>
            <Container>
                <Panel>
                    <Row>
                        <LogoBox href="/">
                            {/* <img src={Logo} alt="Logo" /> */}
                            KICKSAT PREPRATIONS
                        </LogoBox>
                        <Box>
                            <p>Kicksat Preparations was founded in 2019 with a vision to bridge all the gaps in order to make standardized testing accessible and affordable to all.</p>
                        </Box>
                    </Row>
                    <Row>
                        <Box>
                            <Parag>Courses<WANumber href="/sat">SAT</WANumber><WANumber href="/gre">GRE</WANumber><WANumber href="/ielts-toefl">IELTS/TOFEL</WANumber></Parag>
                        </Box>
                    </Row>
                    <Row>
                        <Box>
                            <Parag>
                                <Para>Contact us on <br></br><WANumber href="https://wa.me/923314973171" id="contactFB">WhatsApp</WANumber></Para>
                                <Para><WANumber href="https://www.instagram.com/kicksat/" id="contactFB">Instagram</WANumber></Para>
                                <Para><WANumber href="https://www.facebook.com/kicksatprep/" id="contactFB">Facebook</WANumber></Para>
                                <Para><WANumber href="https://www.linkedin.com/company/kicksat/" id="contactFB">LinkedIn</WANumber></Para>
                            </Parag>
                        </Box>
                    </Row>
                </Panel>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    background-color: #343838;
`;

const Parag = styled.p`
    margin: 0px;
    text-align: right;
    height: 100%;
    max-height: 150px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    @media screen and (max-width: 768px) {
    text-align: left;
    }
`;

const Para = styled.p`
    text-align: left;
    margin: 0;
    font-size: 16px;
`;


const Panel = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    // justify-content: space-between;
    padding: 20px;
    padding-bottom: 30px;
    @media screen and (max-width: 768px) {
        display: block;
    }

`;

const Row = styled.div`
    width: 100%;
    max-width: 900px;
    display: block;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;



const Box = styled.div`
    width: 80%;
    height: 100%;
    padding: 0px;
    color: #D3D3D3;
    font-size: 15px;
    // text-align: justify;
    img{
        height: 100px;
        width: auto;
    }
`;

const LogoBox = styled.a`
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: 700;
    width: 200px;
    height: 100%;
    padding-right: 40px;
    padding-top: 30px;
    // padding-left: 15px;
    img{
        width: 200px;
        height: auto;
    }
`;


const WANumber = styled.a`
    text-decoration: none;
    color: #D3D3D3;
    font-size: 15px;
    font-weight: 500;
    // text-transform: uppercase;
    cursor: pointer;
    :hover{
        font-weight: 800;
    }
    transition: all 200ms ease-in-out;
`;



export default MyFooter;