import styled from "styled-components";
import Header from "./Components/Header";
import MyFooter from "./Components/Footers";
import GREInfo from "./Components/GREInfo";
import GREBanner from "./Components/GREBanner";
import Stories from "./Components/StoriesGrey";

const GRE = () =>  {

    return (
        <>
            <Container>
                <Header/>
                <GREBanner/>
                <GREInfo/>
                <Stories></Stories>
                <MyFooter/>
            </Container>
        </>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: white;
    .grey{
        background: #F3F3F3;
    }
`;



export default GRE;